import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum';
import { LoadingService } from 'src/app/services/loading.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isLoading = false;
  loadingTimeout!: ReturnType<typeof setTimeout>;
  browserLang!: string;

  constructor(
    private router: Router,
    public loadingService: LoadingService,
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    //create script for aba pay
    this._createSdk(environment.ABA_PAYWAY, 'aba-payway-sdk');

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.loadingService.forceStop();
        this.loadingService.setLoading('page', true);
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        this.loadingService.setLoading('page', false);
        this.loadingService.url = '';
      }
    });

    translate.addLangs(['en', 'km']);
    translate.setDefaultLang('km');

    this.browserLang = localStorageService.get(LocalStorageEnum.language); //navigator.language;
    translate.use(this.browserLang.match(/en|zh-CN|km/) ? this.browserLang : 'km'); // default language 'km'
  }

  private _createSdk(src: string, id: string) {
    const script = this._document.createElement('script');
    script.id = id;
    script.defer = true;
    script.src = src;
    this._document.body.appendChild(script);
  }
}
